import { Navbar } from "../../components/Navbar/Navbar";
import { Ship } from "../../components/Ship/Ship";

export const Pedido = () => {
  return (
    <div className="fade-in">
      <Navbar />

      <div className="mt-20">
        <Ship />
      </div>
    </div>
  );
};
