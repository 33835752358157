import { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { PRICESCustoms } from "../Functions/PRICESCustoms";
import Swal from "sweetalert2";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Freight } from "../Freight/Freight";
import { useSelector } from "react-redux";

export const Cart = ({
  showFinishs,
  setFretePrecoCartCustom,
  setTotalCartCustom,
}) => {
  const [visible, setVisible] = useState(false);

  const [carrinho, setCarrinho] = useState(
    JSON.parse(localStorage.getItem("carrinho")) || []
  );

  useEffect(() => {
    localStorage.setItem("carrinho", JSON.stringify(carrinho));
  }, [carrinho]);

  const removeItem = (index) => {
    const updatedCarrinho = carrinho.filter((_, i) => i !== index);
    setCarrinho(updatedCarrinho);
  };

  const confirmRemove = (index) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você realmente deseja remover este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sim, remover",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeItem(index);
        Swal.fire("Removido!", "O item foi removido do carrinho.", "success");
      }
    });
  };

  const prices = carrinho.map((item) => PRICESCustoms(item.comprimento));
  const total = prices.reduce((acc, val) => acc + parseFloat(val), 0);

  const navigate = useNavigate();

  const [fretePreco, setFretePreco] = useState(0);

  const isFrete = () => {
    Swal.fire({
      title: "Frete vazio",
      text: "Antes de finalizar o pedido, selecione um frete",
      icon: "warning",
      showConfirmButton: false,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Vou atualizar meu frete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      }
    });
  };

  const handleFinishOrder = () => {
    if (fretePreco !== 0) {
      setVisible(true);

      setTimeout(() => {
        navigate("/checkout");
      }, 1000);
    } else {
      isFrete();
    }
  };

  useEffect(() => {
    setTotalCartCustom(total);
    setFretePrecoCartCustom(fretePreco);
  }, [total, fretePreco, setFretePrecoCartCustom, setTotalCartCustom]);

  const carrinhoProdutos = useSelector((state) => state.product.produtos);

  const calcularValorTotal = (produtos) => {
    return produtos.reduce((total, produto) => {
      return total + produto.quantity * produto.price;
    }, 0);
  };

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const price = calcularValorTotal(carrinhoProdutos);
    setTotalPrice(price);
  }, [carrinhoProdutos]);

  return (
    <>
      {visible ? (
        <div className={`absolute z-20 bg-white w-full h-full fade-in`} />
      ) : (
        <></>
      )}
      {carrinho.length !== 0 ? (
        <>
          {carrinho.map((item, index) => {
            return (
              <div
                key={index}
                className="m-5 border-b border-[#111111] no-spinner flex justify-between items-end"
              >
                <div>
                  <div>
                    {index + 1} - Tala de {item.comprimento} cm
                  </div>
                  <div className="flex justify-between items-center">
                    <div>Cor estofado </div>
                    <div
                      style={{
                        backgroundColor:
                          item.cor_estofado === ""
                            ? "#000000"
                            : item.cor_estofado,
                      }}
                      className="h-5 w-5 ml-2 border"
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <div>Cor faixa </div>
                    <div
                      style={{
                        backgroundColor:
                          item.cor_faixa === "" ? "#000000" : item.cor_faixa,
                      }}
                      className="h-5 w-5 ml-2 border"
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <div>Cor fita </div>
                    <div
                      style={{
                        backgroundColor:
                          item.cor_fita === "" ? "#000000" : item.cor_fita,
                      }}
                      className="h-5 w-5 ml-2 border"
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <div>Estampado</div>
                    <div
                      style={{
                        backgroundImage: `url(${
                          item.estampa === "" ? "Nenhuma" : item.estampa
                        })`,
                      }}
                      className="h-5 w-5 ml-2 border"
                    />
                  </div>
                  <div>
                    {PRICESCustoms(item.comprimento) === "121212.00" ? (
                      "Consulte com um especialista"
                    ) : (
                      <div className="flex justify-between items-center">
                        <div>R$</div>
                        <div>{PRICESCustoms(item.comprimento)}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-1">
                  <FaTrashAlt
                    size={20}
                    onClick={() => confirmRemove(index)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            );
          })}

          {showFinishs ? (
            <></>
          ) : (
            <>
              <Freight setFretePreco={setFretePreco} />
              <div
                className="m-5 no-spinner flex justify-center hover:cursor-pointer"
                onClick={() => navigate("/checkout")}
              ></div>

              <div className="m-5 border-b border-[#111111] no-spinner flex justify-between items-end">
                <div>Total </div>
                <div>R$ {(total + fretePreco + totalPrice).toFixed(2)}</div>
              </div>
              <div
                className="m-5 no-spinner flex justify-center hover:cursor-pointer"
                onClick={() => handleFinishOrder()}
              >
                Finalizar pedido
              </div>
            </>
          )}
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <MdOutlineRemoveShoppingCart size={98} color="#111111" />
          <div className="mt-4">Seu carrinho está vazio</div>
        </div>
      )}
    </>
  );
};
