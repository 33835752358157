import { useState } from "react";
import { Cart } from "../../components/Cart/Cart";
import { Navbar } from "../../components/Navbar/Navbar";
import { useSelector } from "react-redux";
import { CartProducts } from "../../components/Products/CartProducts";

export const Carrinho = () => {
  const [totalCartCustom, setTotalCartCustom] = useState(0);
  const [fretePrecoCartCustom, setFretePrecoCartCustom] = useState(0);

  const carrinhoProdutos = useSelector((state) => state.product.produtos);

  return (
    <>
      <div className="fade-in">
        <Navbar />

        <div className="mt-20">
          <div className="m-5">
            <CartProducts carrinhoProdutos={carrinhoProdutos} />
          </div>
          <Cart
            showFinishs={false}
            setFretePrecoCartCustom={setFretePrecoCartCustom}
            setTotalCartCustom={setTotalCartCustom}
            values={(totalCartCustom, fretePrecoCartCustom)}
          />
        </div>
      </div>
    </>
  );
};
