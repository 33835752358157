import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Checkout } from "../pages/Checkout/Checkout";
import { Home, Home1, Home2, Home3, Home4 } from "../pages/Home/Home";
import { Carrinho } from "../pages/Carrinho/Carrinho";
import { Falhou } from "../pages/StatusPagamento/Falhou";
import { Sucesso } from "../pages/StatusPagamento/Sucesso";
import { Pendente } from "../pages/StatusPagamento/Pendente";
import { Pedido } from "../pages/StatusPagamento/Pedido";
import { NotFound } from "../pages/NotFound/NotFound";
import { Products } from "../pages/Products/Products";

export const Routers = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/1" element={<Home1 />} />
        <Route path="/2" element={<Home2 />} />
        <Route path="/3" element={<Home3 />} />
        <Route path="/4" element={<Home4 />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/carrinho" element={<Carrinho />} />
        <Route path="/falhou/:orderStatusId" element={<Falhou />} />
        <Route path="/sucesso/:orderStatusId" element={<Sucesso />} />
        <Route path="/pendente/:orderStatusId" element={<Pendente />} />
        <Route path="/pedido" element={<Pedido />} />
        <Route path="/produtos/:customId" element={<Products />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
