import { FaTrashAlt } from "react-icons/fa";
import { NumberSniper } from "./NumberSniper";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

export const CartProducts = ({ carrinhoProdutos }) => {
  const dispatch = useDispatch();

  const confirmRemove = (id) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você realmente deseja remover este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sim, remover",
      cancelButtonText: "Cancelar",
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch({
            type: "REMOVE_PRODUCT",
            payload: { id },
          });

          Swal.fire("Removido!", "O item foi removido do carrinho.", "success");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div>
        {carrinhoProdutos.map((item, index) => {
          return (
            <div
              key={index}
              className="border-b border-[#111111] no-spinner flex justify-between items-end pb-1"
            >
              <div className="gap-1 flex flex-col">
                <div className="flex justify-between items-center">
                  {index + 1} - {item.product}
                </div>

                <div className="flex justify-between items-center">
                  <p>Tipo</p>
                  <span
                    style={{ background: item.type }}
                    className="w-[16px] h-[16px] inline-block border-none"
                  />
                </div>

                <div className="flex justify-between items-center">
                  <p>Tamanho</p>
                  <p>{item.size}</p>
                </div>

                <div className="flex justify-between items-center">
                  <NumberSniper quantity={item.quantity} id={item.id} />
                </div>

                <div className="flex justify-between items-center">
                  <p>R$</p>
                  <p>{item.price.toFixed(2)}</p>
                </div>
              </div>
              <div className="cursor-pointer">
                <FaTrashAlt size={20} onClick={() => confirmRemove(item.id)} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
