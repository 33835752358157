import { combineReducers } from "redux";
import priceReducer from "./priceReducer";
import itemReducer from "./itemReducer";
import toggleReducer from "./toggleReducer";
import productReducer from "./productReducer";

const rootReducer = combineReducers({
  price: priceReducer,
  item: itemReducer,
  toggle: toggleReducer,
  product: productReducer,
});

export default rootReducer;
