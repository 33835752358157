import { LiaStarSolid } from "react-icons/lia";

export const Comments = ({ comments }) => {
  return (
    <>
      <div className="flex justify-center border-t border-black w-full md:max-w-screen-lg sm:max-w-screen-sm mx-auto pt-3">
        <div className="w-full p-2">
          {comments.map((item, index) => {
            return (
              <div key={index} className="flex justify-between center">
                <div className="flex justify-center">
                  <div className="flex justify-between items-center w-full max-w-md">
                    <img
                      src="/images/no-user.jpg"
                      alt="no-user"
                      className="h-auto w-12"
                    />
                    <div>{item.comment}</div>
                  </div>
                </div>

                <div className="pr-2 flex gap-1">
                  {Array.from({ length: 5 }, (_, index) => (
                    <LiaStarSolid
                      key={index}
                      style={{
                        color: index < item.points ? "#f5841f" : "gray",
                      }}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
