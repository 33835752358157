import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import Swal from "sweetalert2";

export const Infos = ({ id, msgI, product, msgII, price, types, sizes }) => {
  const [typeI, setTypeI] = useState(null);
  const handleType = (i) => {
    setTypeI(i);
  };

  const [sizeI, setSizeI] = useState(null);
  const handleSize = (i) => {
    setSizeI(i);
  };

  const [n, setN] = useState(1);
  const increment = () => {
    if (n < 100) {
      setN(n + 1);
    }
  };

  const decrement = () => {
    if (n > 0) {
      setN(n - 1);
    }
  };

  const dispatch = useDispatch();

  const handleClickCarrinho = () => {
    if (!types[typeI] || !sizes[sizeI]) {
      Swal.fire({
        title: "Alerta!",
        text: "Você deve selecionar as características do produto.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e5e5e5",
        customClass: {
          confirmButton: "custom-confirm-btn",
        },
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Ver meu carrinho",
        cancelButtonText: "Voltar",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch({ type: "TOGGLE", payload: true });
        }
      });
    } else {
      try {
        const hashId = uuidv4();

        const novoItem = {
          _id: id,
          id: hashId,
          quantity: n,
          product: product,
          price: price,
          type: types[typeI],
          size: sizes[sizeI],
        };

        dispatch({
          type: "ADD_PRODUCT",
          payload: novoItem,
        });

        dispatch({ type: "TOGGLE", payload: true });
      } catch (err) {
        console.log(err);
        localStorage.removeItem("carrinho-produtos");
      }
    }
  };

  return (
    <>
      <div className="flex flex-col justify-between">
        <div className="overflow-auto">
          <div className="text-start text-orange-600 font-bold">{msgI}</div>
          <div className="text-start text-2xl">{product}</div>
          <div className="text-start text-gray-400 font-bold text-sm sm:max-w-48 md:max-w-96">
            {msgII}
          </div>
          <div className="text-start font-bold mt-3">R$ {price}</div>
          <div className="text-start font-bold mt-3">Selecione seu tipo</div>
          <div className="flex flex-wrap sm:max-w-48 md:max-w-96 gap-2 mt-1">
            {types.map((item, index) => {
              return (
                <button
                  style={{
                    background: item,
                    border: typeI === index ? "2px solid #93c5fd" : "",
                  }}
                  onClick={() => handleType(index)}
                  className="w-8 h-8 cursor-pointer"
                  key={index}
                />
              );
            })}
          </div>
          <div className="text-start font-bold mt-3">Selecione um tamanho</div>
          <div className="flex flex-wrap sm:max-w-64 xl:max-w-96 gap-2 mt-1">
            {sizes.map((item, index) => {
              return (
                <button
                  style={{
                    background: item,
                    border: sizeI === index ? "2px solid #93c5fd" : "",
                  }}
                  onClick={() => handleSize(index)}
                  className="p-4 rounded-md w-24 h-8 cursor-pointer border-slate-200 border-2 flex justify-center items-center"
                  key={index}
                  type="button"
                >
                  {item}
                </button>
              );
            })}
          </div>
          <div className="flex items-center mt-3 gap-5">
            <div>Quantidade:</div>

            <div className="flex items-center justify-between">
              <button
                onClick={decrement}
                className="bg-gray-200 p-1 w-7 h-7 flex justify-center items-center rounded-s-md"
              >
                -
              </button>
              <div className="text-center w-12 border-gray-200 border-2 p-1 h-7 flex justify-center items-center">
                {n}
              </div>
              <button
                onClick={increment}
                className="bg-gray-200 p-1 w-7 h-7 flex justify-center items-center rounded-r-md"
              >
                +
              </button>
            </div>
          </div>
        </div>
        <button
          className="bg-[#111111] text-white p-4 rounded-full mt-10"
          onClick={handleClickCarrinho}
        >
          Adicionar ao carrinho
        </button>
      </div>
    </>
  );
};
