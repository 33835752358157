import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

export const NumberSniper = ({ quantity, id }) => {
  const dispatch = useDispatch();
  const [n, setN] = useState(quantity);

  useEffect(() => {
    setN(quantity);
  }, [quantity]);

  const increment = () => {
    if (n < 100) {
      const newQuantity = n + 1;
      setN(newQuantity);
      dispatch({
        type: "UPDATE_QUANTITY",
        payload: { id, quantity: newQuantity },
      });
    }
  };

  const decrement = () => {
    if (n > 0) {
      const newQuantity = n - 1;
      setN(newQuantity);
      dispatch({
        type: "UPDATE_QUANTITY",
        payload: { id, quantity: newQuantity },
      });
    }
  };

  return (
    <div className="flex items-center gap-5">
      <div>Quantidade</div>
      <div className="flex items-center justify-between">
        <button
          onClick={decrement}
          className="bg-gray-300 p-1 w-7 h-7 flex justify-center items-center rounded-s-md"
        >
          -
        </button>
        <div className="text-center w-12 border-gray-300 border-2 p-1 h-7 flex justify-center items-center">
          {n}
        </div>
        <button
          onClick={increment}
          className="bg-gray-300 p-1 w-7 h-7 flex justify-center items-center rounded-r-md"
        >
          +
        </button>
      </div>
    </div>
  );
};
