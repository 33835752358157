import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoMdCart } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";

export const Navbar = ({ onCart }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const state = useSelector((state) => state.toggle.isActive);
  const handleToggle = () => {
    dispatch({ type: "TOGGLE", payload: !state });
  };

  return (
    <>
      <div className="flex justify-between items-center h-16 bg-[#111111] text-white p-5 fixed z-20 w-full top-0">
        <div onClick={() => navigate("/")}>
          <IoIosArrowRoundBack size={32} />
        </div>
        <div className="flex justify-center items-center gap-5">
          <div
            style={{ display: onCart ? "" : "none" }}
            className="cursor-pointer"
            onClick={handleToggle}
          >
            <IoMdCart size={40} />
          </div>

          <img src="/logo192.png" alt="logo" className="h-12 w-auto" />
        </div>
      </div>
    </>
  );
};
