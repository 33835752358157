import { useDispatch, useSelector } from "react-redux";
import { LuShoppingBag } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { useState } from "react";
import Swal from "sweetalert2";
import { CustomSearch } from "./CustomSearchs";

export const CustomPrices = () => {
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();
  const preco = useSelector((state) => state.price.preco);
  const isPreco121212 = preco === "121212.00";

  const confirmFeito = () => {
    Swal.fire({
      title: "Alerta!",
      text: "Você deve colocar um tamanho para a tala",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#e5e5e5",
      customClass: {
        confirmButton: "custom-confirm-btn",
      },
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Ver meu carrinho",
      cancelButtonText: "Voltar",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/carrinho");
      }
    });
  };

  const dispatch = useDispatch();

  const talaCarrinho = useSelector((state) => state.item);

  const HandleClick = (caminho, ignore) => {
    if (preco !== null) {
      setVisible(true);

      const carrinho = localStorage.getItem("carrinho");
      const carrinhoArray = carrinho ? JSON.parse(carrinho) : [];

      if (talaCarrinho) {
        carrinhoArray.push(...talaCarrinho);
      }

      localStorage.setItem("carrinho", JSON.stringify(carrinhoArray));

      const payload = {
        comprimento: "",
        cor_faixa: "",
        cor_fita: "",
        cor_estofado: "",
        estampa: "",
      };

      dispatch({ type: "SET_PRECO", payload: null });
      dispatch({ type: "SET_ITEM", payload: payload });

      setTimeout(() => {
        navigate(caminho);
      }, 1000);
    } else {
      if (ignore) {
        setVisible(true);
        setTimeout(() => {
          navigate(caminho);
        }, 1000);
      } else {
        confirmFeito();
      }
    }
  };

  const ButtonSearch = ({ bar, modifyStateBar, oppen = false }) => {
    const [state, setState] = useState(oppen);
    const modifyState = () => {
      if (!bar) {
        setState(!state);
      } else {
        modifyStateBar();
      }
    };

    return (
      <>
        <button
          className={`${
            state || oppen ? "w-full" : "w-11"
          } rounded-full bg-gray-200 flex items-center justify-between h-11 transition-all duration-300`}
        >
          {state ? (
            <div className="flex justify-center w-full h-full">
              <CustomSearch />
            </div>
          ) : (
            <div></div>
          )}

          <div
            className={`w-11 h-11 min-w-11 flex justify-center items-center rounded-full transition-all duration-300 ${
              state ? "bg-gray-300" : ""
            }`}
            onClick={modifyState}
          >
            {state || oppen ? <FaSearch /> : <LuShoppingBag />}
          </div>
        </button>
      </>
    );
  };

  const [state, setStateBar] = useState(false);
  const modifyState = () => setStateBar(!state);

  return (
    <>
      {visible ? (
        <div className={`absolute z-20 bg-white w-full h-full fade-in`} />
      ) : (
        <></>
      )}
      <div className="w-full absolute top-0 flex flex-col text-sm  bg-transparent z-10">
        <div className="relative">
          <div
            className={`flex items-center justify-center p-2 absolute w-full h-16 transition-all duration-500 ease-in-out ${
              state
                ? "opacity-100 translate-y-0"
                : "opacity-0 -translate-y-full"
            }`}
          >
            <ButtonSearch bar={false} oppen={true} />
          </div>

          <div
            className={`${state ? "h-16" : "h-0"} transition-all duration-500`}
          ></div>
        </div>

        <div className="flex justify-between items-center pr-10 pl-10 pt-10">
          <div className="w-64">
            <div>Tala extensora customizável</div>
            <div>
              $
              {preco !== null
                ? isPreco121212
                  ? " Consultar com especialista"
                  : preco
                : " --:--"}
            </div>
          </div>

          <div className="flex space-x-2 w-full justify-end">
            <div className="hidden md:flex w-full justify-end">
              <ButtonSearch bar={false} />
            </div>
            <div className="flex md:hidden w-full justify-end">
              <ButtonSearch bar={true} modifyStateBar={modifyState} />
            </div>
            {/* //celulares */}
            <button
              className="rounded-full bg-gray-200 flex items-center justify-center min-w-11 w-11 h-11"
              onClick={() => HandleClick("/pedido", true)}
            >
              ↑
            </button>
            <button
              className="rounded-full border px-6 py-3 border-gray-300 bg-gray-50"
              onClick={() => HandleClick("/carrinho", false)}
            >
              Feito
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
