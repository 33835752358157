import { makeRequest } from "../Axios/MakeRequest";
import { useQuery } from "@tanstack/react-query";
import { matchSorter } from "match-sorter";
import { useMemo, useState } from "react";

export const CustomSearch = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["products"],
    queryFn: () =>
      makeRequest
        .get("/product/ids", {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
          },
        })
        .then((res) => res.data),
  });

  function useProductMatch(term, data) {
    return useMemo(() => {
      if (!data || term.trim() === "") return [];
      return matchSorter(data, term, {
        keys: ["product"],
      });
    }, [term, data]);
  }

  const [term, setTerm] = useState("");
  const results = useProductMatch(term, data);
  const handleChange = (event) => setTerm(event.target.value);

  return (
    <div className="w-full">
      <input
        type="text"
        className="w-[calc(100%-1.5rem)] outline-none h-full bg-transparent"
        placeholder="Pesquise"
        value={term}
        onChange={handleChange}
        autoFocus
      />

      {/* Resultados */}
      <div className="relative mt-2">
        {isLoading ? (
          <div className="p-2">Carregando...</div>
        ) : error ? (
          <div className="p-2 text-red-500">
            Ocorreu um erro ao carregar os produtos.
          </div>
        ) : data && results.length > 0 ? (
          <ul className="border border-gray-300 rounded-md bg-white shadow-md max-h-60 overflow-y-auto">
            {results.slice(0, 10).map((result, index) => (
              <li
                key={index}
                className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
                onClick={() =>
                  (window.location.href = `/produtos/${result._id}`)
                }
              >
                <img
                  src={result.images}
                  alt={result.product}
                  className="w-12 h-12 mr-2 object-cover rounded-md"
                />
                <span>{result.product}</span>
              </li>
            ))}
          </ul>
        ) : (
          term && (
            <div className="p-2 text-gray-500">
              Ainda não temos o que procura :(
            </div>
          )
        )}
      </div>
    </div>
  );
};
