import { useState } from "react";
import { makeRequest } from "../Axios/MakeRequest";
import Swal from "sweetalert2";

export const Freight = ({ setFretePreco }) => {
  const [frete, setFrete] = useState([]);
  const [cepFrete, setCepFrete] = useState("");
  const [statusFrete, setStatusFrete] = useState("");

  const calculate_frete = () => {
    Swal.fire({
      title: "Calcular frete",
      icon: "warning",
      input: "text",
      inputAttributes: {
        placeholder: "Digite seu CEP",
        "aria-label": "Digite seu CEP",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Você precisa inserir um CEP!";
        }
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#e5e5e5",
      confirmButtonText: "Enviar",
      cancelButtonText: "Voltar",
      customClass: {
        input:
          "border border-gray-300 rounded-md p-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500",
        cancelButton: "custom-confirm-btn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setStatusFrete("loading");
        const cep = result.value;
        try {
          let response;

          response = await makeRequest.post(
            "calcula/frete",
            {
              address: {
                cep_formatado: cep,
              },
              servicos: ["E", "X"],
              tala: JSON.parse(localStorage.getItem("carrinho")),
              prod: (
                JSON.parse(localStorage.getItem("carrinho-produtos")) || []
              ).map((item) => ({
                id: item._id,
                quantidade: item.quantity,
                type: item.type,
                size: item.size,
              })),
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
              },
            }
          );

          const res_data = await response.data;
          setFrete(res_data);
          setCepFrete(cep);

          const result = Array.isArray(res_data)
            ? res_data.find((item) => item.servico === "X")?.vlrFrete || 0
            : 0;
          setFretePreco(result);
          setStatusFrete("pass");
          localStorage.setItem(
            "frete",
            JSON.stringify([{ servico: "X", cep: cep }])
          );
        } catch (error) {
          console.error("Erro na requisição:", error);
          setStatusFrete("error");
        }

        Swal.close();
      }
    });
  };

  const handleChange = (e) => {
    const frete = JSON.parse(e.target.value);
    setFretePreco(frete.frete);

    localStorage.setItem(
      "frete",
      JSON.stringify([{ servico: frete.servico, cep: frete.cep }])
    );
  };

  return (
    <>
      <div className="m-5 border-b border-[#111111] no-spinner flex justify-between items-end">
        <div onClick={() => calculate_frete()} className="hover:cursor-pointer">
          Calcular Frete
        </div>
        <div>
          <>
            {statusFrete === "loading" ? (
              <div className="loader" />
            ) : statusFrete === "error" ? (
              "Ops, houve um erro. Volte mais tarde"
            ) : statusFrete === "pass" ? (
              <>
                <select
                  className="outline-none webkit-none"
                  onChange={(e) => handleChange(e)}
                >
                  {Array.isArray(frete) ? (
                    frete.map((item, index) => (
                      <option
                        value={JSON.stringify({
                          servico: item.servico,
                          frete: item.vlrFrete,
                          cep: cepFrete,
                        })}
                        key={index}
                      >
                        {item.servico === "X"
                          ? `Sedex ${item.prazoEntMin}/${item.prazoEnt} dias`
                          : `(PAC) ${item.prazoEntMin}/${item.prazoEnt} dias`}{" "}
                        - R$ {item.vlrFrete.toFixed(2)}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      Nenhum frete disponível
                    </option>
                  )}
                </select>
              </>
            ) : (
              <div onClick={() => calculate_frete()} className="cursor-pointer">
                --:--
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};
