import { useParams } from "react-router-dom";
import { Image } from "./Image";
import { Infos } from "./Infos";
import { Comments } from "./Comments";
import { makeRequest } from "../Axios/MakeRequest";
import { useQuery } from "@tanstack/react-query";

export const Product = () => {
  const { customId } = useParams();

  // const productData = [
  //   {
  //     customId: "meias",
  //     images: [
  //       "https://images.pexels.com/photos/1459505/pexels-photo-1459505.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //       "https://swiperjs.com/demos/images/nature-1.jpg",
  //       "https://i.ibb.co/Y7MTz33/pexels-photo-251454.jpg",
  //       "https://i.ibb.co/Y7MTz33/pexels-photo-251454.jpg",
  //       "https://i.ibb.co/Y7MTz33/pexels-photo-251454.jpg",
  //     ],
  //     msgI: "Material sustentável",
  //     product: "Meias especiais",
  //     msgII: "Feito para todas as idades e tamanhos",
  //     price: 100,
  //     types: ["#000000", "#000000", "#000000", "#000000", "#000000"],
  //     sizes: ["XS", "XS", "XS", "XS", "XS", "XS", "XS"],
  //     comments: [
  //       { comment: "Muito bom, qualidade incrível.", points: 3 },
  //       { comment: "Muito bom, qualidade incrível.", points: 5 },
  //       { comment: "Muito bom, qualidade incrível.", points: 5 },
  //       { comment: "Muito bom, qualidade incrível.", points: 5 },
  //       { comment: "Muito bom, qualidade incrível.", points: 5 },
  //     ],
  //   },
  //   {
  //     customId: "camisetas",
  //     images: [
  //       "https://i.ibb.co/Y7MTz33/pexels-photo-251454.jpg",
  //       "https://i.ibb.co/Y7MTz33/pexels-photo-251454.jpg",
  //       "https://i.ibb.co/Y7MTz33/pexels-photo-251454.jpg",
  //       "https://i.ibb.co/Y7MTz33/pexels-photo-251454.jpg",
  //       "https://i.ibb.co/Y7MTz33/pexels-photo-251454.jpg",
  //     ],
  //     msgI: "Material sustentável",
  //     product: "Camisetas especiais",
  //     msgII: "Feito para todas as idades e tamanhos",
  //     price: 80,
  //     types: ["#9b2828", "#000000", "#10238f", "#000000", "#08c931"],
  //     sizes: ["XS", "XS", "XS", "XS", "XS", "XS", "XS"],
  //     comments: [
  //       { comment: "Muito bom, qualidade incrível.", points: 3 },
  //       { comment: "Muito bom, qualidade incrível.", points: 5 },
  //       { comment: "Muito bom, qualidade incrível.", points: 5 },
  //       { comment: "Muito bom, qualidade incrível.", points: 5 },
  //       { comment: "Muito bom, qualidade incrível.", points: 5 },
  //     ],
  //   },
  // ];

  // const searchData = productData.find(
  //   (product) => product.customId === customId
  // );

  const { isLoading, error, data } = useQuery({
    queryKey: ["produtos"],
    queryFn: () =>
      makeRequest
        .get(`/product/${customId}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
          },
        })
        .then((res) => res.data),
  });

  return (
    <>
      {isLoading ? (
        "Carregando..."
      ) : error ? (
        "Erro. Volte mais tarde"
      ) : data ? (
        <>
          <div className="max-w-full">
            <div className="sm:flex justify-center gap-10 p-5 h-full max-w-screen-lg mx-auto">
              <Image images={data.images} />
              <Infos
                id={data._id}
                msgI={data.msgI}
                msgII={data.msgII}
                product={data.product}
                price={data.price}
                types={data.types}
                sizes={data.sizes}
              />
            </div>
            <Comments comments={data.comments} />
          </div>
        </>
      ) : (
        "Erro. Volte mais tarde"
      )}
    </>
  );
};
