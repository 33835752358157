import { Navbar } from "../../components/Navbar/Navbar";
import { Product } from "../../components/Products/Product";
import { RightSidebar } from "../../components/Products/RightSidebar";

export const Products = () => {
  return (
    <div className="fade-in">
      <Navbar onCart={true} />

      <div className="mt-16">
        <RightSidebar />
        <Product />
      </div>
    </div>
  );
};
