const initialState = {
  produtos: JSON.parse(localStorage.getItem("carrinho-produtos")) || [],
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_PRODUCT":
      const novosProdutos = [...state.produtos, action.payload];
      localStorage.setItem("carrinho-produtos", JSON.stringify(novosProdutos));
      return {
        ...state,
        produtos: novosProdutos,
      };

    case "REMOVE_PRODUCT":
      const produtosRestantes = state.produtos.filter(
        (produto) => produto.id !== action.payload.id
      );
      localStorage.setItem(
        "carrinho-produtos",
        JSON.stringify(produtosRestantes)
      );
      return {
        ...state,
        produtos: produtosRestantes,
      };

    case "UPDATE_QUANTITY":
      const produtosComQuantidadeAtualizada = state.produtos.map((produto) =>
        produto.id === action.payload.id
          ? { ...produto, quantity: action.payload.quantity }
          : produto
      );
      localStorage.setItem(
        "carrinho-produtos",
        JSON.stringify(produtosComQuantidadeAtualizada)
      );
      return {
        ...state,
        produtos: produtosComQuantidadeAtualizada,
      };

    default:
      return state;
  }
};

export default productReducer;
