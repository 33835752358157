import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Freight } from "../Freight/Freight";
import { useNavigate } from "react-router-dom";
import { Cart } from "../Cart/Cart";
import { CartProducts } from "./CartProducts";

export const RightSidebar = () => {
  const [visible, setVisible] = useState(false);

  const state = useSelector((state) => state.toggle.isActive);

  const carrinhoProdutos = useSelector((state) => state.product.produtos);

  const [fretePreco, setFretePreco] = useState(0);
  const navigate = useNavigate();

  const isFrete = () => {
    Swal.fire({
      title: "Frete vazio",
      text: "Antes de finalizar o pedido, selecione um frete",
      icon: "warning",
      showConfirmButton: false,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Vou atualizar meu frete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      }
    });
  };

  const handleFinishOrder = () => {
    if (fretePreco !== 0) {
      setVisible(true);

      setTimeout(() => {
        navigate("/checkout");
      }, 1000);
    } else {
      isFrete();
    }
  };

  // const data = JSON.parse(localStorage.getItem("carrinho-produtos")) || [];

  // const carrinho = data.map((item) => ({
  //   id: item._id,
  //   quantidade: item.quantity,
  // }));

  const calcularValorTotal = (produtos) => {
    return produtos.reduce((total, produto) => {
      return total + produto.quantity * produto.price;
    }, 0);
  };

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const price = calcularValorTotal(carrinhoProdutos);
    setTotalPrice(price);
  }, [carrinhoProdutos]);

  const [totalCartCustom, setTotalCartCustom] = useState(0);
  const [fretePrecoCartCustom, setFretePrecoCartCustom] = useState(0);

  return (
    <>
      {visible ? (
        <div className={`absolute z-20 bg-white w-full h-full fade-in`} />
      ) : (
        <></>
      )}
      <div
        className="h-[calc(100%-64px)] w-72 md:w-80 xl:w-96 fixed bg-gray-200 rounded-3xl z-10 shadow-lg border p-4 transition-all duration-500 overflow-auto flex flex-col"
        style={{ right: state ? "0px" : "-100%" }}
      >
        <div className="mb-3">Produtos da loja:</div>
        <div className="flex flex-col flex-1 overflow-auto overflow-x-hidden">
          <CartProducts carrinhoProdutos={carrinhoProdutos} />
          <div className="mt-3">Produtos especiais:</div>

          <div className="-m-5 mt-1">
            <Cart
              showFinishs={true}
              setFretePrecoCartCustom={setFretePrecoCartCustom}
              setTotalCartCustom={setTotalCartCustom}
            />
          </div>
        </div>
        <Freight setFretePreco={setFretePreco} />
        <div className="m-5 border-b border-[#111111] no-spinner flex justify-between items-end">
          <div>Total</div>
          <div>
            {(
              totalPrice +
              fretePreco +
              totalCartCustom +
              fretePrecoCartCustom
            ).toFixed(2)}
          </div>
        </div>
        <button
          className="bg-[#111111] text-white p-4 rounded-full mt-3 w-full"
          onClick={handleFinishOrder}
        >
          Finalizar
        </button>
      </div>
    </>
  );
};
